import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Tabs } from 'hds-react';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/tabs/customisation",
  "title": "Tabs - Customisation"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component. See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--tab-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the tab text label.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--tab-active-border-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the active tab indicator.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`{() => {
  const theme = {
    '--tab-color': 'var(--color-black-90)',
    '--tab-active-border-color': 'var(--color-metro)',
  };
  return (
    <Tabs theme={theme}>
      <Tabs.TabList style={{ marginBottom: 'var(--spacing-m)' }}>
        <Tabs.Tab>Daycare</Tabs.Tab>
        <Tabs.Tab>Pre-school</Tabs.Tab>
        <Tabs.Tab>Basic education</Tabs.Tab>
        <Tabs.Tab>Upper secondary</Tabs.Tab>
        <Tabs.Tab>University</Tabs.Tab>
      </Tabs.TabList>
      <Tabs.TabPanel>
        Daytime care for people who cannot be fully independent, such as children or elderly people.
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        A pre-school is an educational establishment offering early childhood education to children before they begin
        compulsory education at primary school.
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        The objective of basic education in Finland is to support pupils&#39; growth towards humanity and ethically
        responsible membership of society.
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        Upper secondary school studies last three to four years, preparing the students for the matriculation
        examination.
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        A high-level educational institution in which students study for degrees and academic research is done.
      </Tabs.TabPanel>
    </Tabs>
  );
}}
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      